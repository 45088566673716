import PropTypes from 'prop-types';
import { useEffect, useState, useContext } from 'react';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';

// CONTEXTS
import { ModalContext } from 'contexts/ModalContext';

// ASSETS
import { AiOutlineSave, AiOutlineDelete } from 'react-icons/ai';

const StudentsFormDetailByTeacher = ({ fields, handleSubmit, handleDelete, isNew }) => {
  const { openModal } = useContext(ModalContext);

  const handleDeleteModal = (id) => {
    const modalData = {
      id,
      handleDelete,
      type: 'DELETE',
    };
    openModal(modalData);
  };

  const formatDate = (date) => {
    if (!date) return null;
    const d = new Date(date);
    const month = `0${d.getMonth() + 1}`.slice(-2);
    const day = `0${d.getDate()}`.slice(-2);
    return `${d.getFullYear()}-${month}-${day}`;
  };

  const initialValues = {
    ...fields,
    DATA_NAIXAMENT: formatDate(fields.DATA_NAIXAMENT),
    INICI_PRACTIQUES: formatDate(fields.INICI_PRACTIQUES),
    FI_PRACTIQUES: formatDate(fields.FI_PRACTIQUES),
  };

  const dniRegex = /^[0-9]{8}[A-Z]$/; // Ejemplo: 12345678A
  const nieRegex = /^[XYZ][0-9]{7}[A-Z]$/; // Ejemplo: X1234567A
  const passportRegex = /^[A-Z0-9]{5,9}$/; // Ejemplo: A123456

  return (
    <Formik
      enableReinitialize={true}
      initialValues={initialValues}
      onSubmit={(values) => {
        handleSubmit(values);
      }}
      validationSchema={Yup.object({
        // CODI_BECARI: Yup.string().required('Required'),
        NOM: Yup.string()
          .matches(/^[a-zA-ZáéíóúÁÉÍÓÚñÑ\s]*$/, 'Solo se permiten letras y espacios')
          .required('Requerido'),
        // COGNOM: Yup.string()
        //   .matches(/^[a-zA-ZáéíóúÁÉÍÓÚñÑ\s]*$/, 'Solo se permiten letras y espacios')
        //   .required('Requerido'),
        DNI: Yup.string()
          .required('Requerido')
          .test('dni-nie-pass', 'Debe ser un DNI, NIE o Pasaporte válido', value => {
            return dniRegex.test(value) || nieRegex.test(value) || passportRegex.test(value);
          }),
        DATA_NAIXAMENT: Yup.date().required('Requerido'),
        NUM_SS: Yup.string()
          .matches(/^\d{12}$/, 'El número de Seguridad Social debe tener 12 dígitos')
          .required('Requerido'),
        INICI_PRACTIQUES: Yup.date().nullable(),
        FI_PRACTIQUES: Yup.date().nullable(),
      })}

    >
      {({ values, errors, touched }) => (
        <Form className="flex flex-col bg-blue-100 border-2 border-indigo-800 rounded-lg p-6">
        <div className="grid grid-cols-12 gap-6">
          <div className="col-span-12">
            <h2 className="text-lg font-semibold text-indigo-900 mb-4">Informació Bàsica</h2>
            <div className="grid grid-cols-12 gap-6 bg-white border-2 border-blue-200 p-4 rounded-lg">
            {/* CODI_BECARI
            <div title="CODI_BECARI" className="col-span-3 row-span-1">
              <div className="flex flex-row items-center mb-3">
                <label className="block mr-2 text-base font-bold leading-4 text-green-700 font-ms-semi print:mt-4" htmlFor="CODI_BECARI">
                  Codi Becari
                </label>
                <ErrorMessage name="CODI_BECARI" render={(message) => <span className="text-sm font-bold leading-4 text-red-600">{message}</span>} />
              </div>
              <Field
                className={`pl-2 rounded-sm w-full appearance-none px-3 p-2 border outline-none text-grey ${
                  touched.CODI_BECARI && errors.CODI_BECARI ? 'border-red-200 bg-red-50 hover:border-red-400 focus:border-red-400' : 'border-gray-300 hover:border-gray-400 focus:border-gray-400'
                }`}
                name="CODI_BECARI"
                type="text"
                placeholder="Escriure..."
              />
            </div> */}
            <div className="col-span-8">
              {/* NOM */}
              <div title="NOM" className="col-span-6 row-span-1">
                <div className="flex flex-row items-center mb-3">
                  <label className="block mr-2 text-base font-bold leading-4 text-indigo-800 font-ms-semi print:mt-4" htmlFor="NOM">
                    Cognoms i Nom
                  </label>
                  <ErrorMessage name="NOM" render={(message) => <span className="text-sm font-bold leading-4 text-red-600">{message}</span>} />
                </div>
                <Field
                  className={`pl-2 rounded-sm w-full appearance-none px-3 p-2 border outline-none text-grey ${
                    touched.NOM && errors.NOM ? 'border-red-200 bg-red-50 hover:border-red-400 focus:border-red-400' : 'border-gray-300 hover:border-gray-400 focus:border-gray-400'
                  }`}
                  name="NOM"
                  type="text"
                  placeholder="Escriure..."
                />
              </div>
            </div>

            {/* <div className="col-span-4"> */}
              {/* COGNOM */}
              {/* <div title="COGNOM" className="col-span-6 row-span-1">
                <div className="flex flex-row items-center mb-3">
                  <label className="block mr-2 text-base font-bold leading-4 text-indigo-800 font-ms-semi print:mt-4" htmlFor="COGNOM">
                    Cognom
                  </label>
                  <ErrorMessage name="COGNOM" render={(message) => <span className="text-sm font-bold leading-4 text-red-600">{message}</span>} />
                </div>
                <Field
                  className={`pl-2 rounded-sm w-full appearance-none px-3 p-2 border outline-none text-grey ${
                    touched.COGNOM && errors.COGNOM ? 'border-red-200 bg-red-50 hover:border-red-400 focus:border-red-400' : 'border-gray-300 hover:border-gray-400 focus:border-gray-400'
                  }`}
                  name="COGNOM"
                  type="text"
                  placeholder="Escriure..."
                />
              </div> */}
            {/* </div> */}

            <div className="col-span-4">
              {/* DNI */}
              <div title="DNI" className="col-span-3 row-span-1">
                <div className="flex flex-row items-center mb-3">
                  <label className="block mr-2 text-base font-bold leading-4 text-indigo-800 font-ms-semi print:mt-4" htmlFor="DNI">
                    DNI
                  </label>
                  <ErrorMessage name="DNI" render={(message) => <span className="text-sm font-bold leading-4 text-red-600">{message}</span>} />
                </div>
                <Field
                  className={`pl-2 rounded-sm w-full appearance-none px-3 p-2 border outline-none text-grey ${
                    touched.DNI && errors.DNI ? 'border-red-200 bg-red-50 hover:border-red-400 focus:border-red-400' : 'border-gray-300 hover:border-gray-400 focus:border-gray-400'
                  }`}
                  name="DNI"
                  type="text"
                  placeholder="Escriure..."
                />
              </div>
            </div>
            <div className="col-span-6">
              {/* DATA_NAIXAMENT */}
              <div title="DATA_NAIXAMENT" className="col-span-3 row-span-1">
                <div className="flex flex-row items-center mb-3">
                  <label className="block mr-2 text-base font-bold leading-4 text-indigo-800 font-ms-semi print:mt-4" htmlFor="DATA_NAIXAMENT">
                    Data Naixement
                  </label>
                  <ErrorMessage name="DATA_NAIXAMENT" render={(message) => <span className="text-sm font-bold leading-4 text-red-600">{message}</span>} />
                </div>
                <Field
                  className={`pl-2 rounded-sm w-full appearance-none px-3 p-2 border outline-none text-grey ${
                    touched.DATA_NAIXAMENT && errors.DATA_NAIXAMENT ? 'border-red-200 bg-red-50 hover:border-red-400 focus:border-red-400' : 'border-gray-300 hover:border-gray-400 focus:border-gray-400'
                  }`}
                  name="DATA_NAIXAMENT"
                  type="date"
                />
              </div>
            </div>
            <div className="col-span-6">
              {/* NUM_SS */}
              <div title="NUM_SS" className="col-span-3 row-span-1">
                <div className="flex flex-row items-center mb-3">
                  <label className="block mr-2 text-base font-bold leading-4 text-indigo-800 font-ms-semi print:mt-4" htmlFor="NUM_SS">
                    Num SS
                  </label>
                  <ErrorMessage name="NUM_SS" render={(message) => <span className="text-sm font-bold leading-4 text-red-600">{message}</span>} />
                </div>
                <Field
                  className={`pl-2 rounded-sm w-full appearance-none px-3 p-2 border outline-none text-grey ${
                    touched.NUM_SS && errors.NUM_SS ? 'border-red-200 bg-red-50 hover:border-red-400 focus:border-red-400' : 'border-gray-300 hover:border-gray-400 focus:border-gray-400'
                  }`}
                  name="NUM_SS"
                  type="text"
                  placeholder="Escriure..."
                />
              </div>
            </div>
          </div>
        


        <div className="col-span-12">
          <h2 className="text-lg font-semibold text-indigo-900 mb-4">Pràctiques</h2>
          <div className="grid grid-cols-12 gap-6 bg-white border-2 border-blue-200 p-4 rounded-lg">
            <div className="col-span-12">
              {/* INICI_PRACTIQUES */}
              <div title="INICI_PRACTIQUES" className="col-span-3 row-span-1">
                <div className="flex flex-row items-center mb-3">
                  <label className="block mr-2 text-base font-bold leading-4 text-indigo-800 font-ms-semi print:mt-4" htmlFor="INICI_PRACTIQUES">
                    Inici Pràctiques
                  </label>
                  <ErrorMessage name="INICI_PRACTIQUES" render={(message) => <span className="text-sm font-bold leading-4 text-red-600">{message}</span>} />
                </div>
                <Field
                  className={`pl-2 rounded-sm w-full appearance-none px-3 p-2 border outline-none text-grey ${
                    touched.INICI_PRACTIQUES && errors.INICI_PRACTIQUES ? 'border-red-200 bg-red-50 hover:border-red-400 focus:border-red-400' : 'border-gray-300 hover:border-gray-400 focus:border-gray-400'
                  }`}
                  name="INICI_PRACTIQUES"
                  type="date"
                />
              </div>
            </div>
          

          <div className="col-span-12">
              {/* FI_PRACTIQUES */}
              <div title="FI_PRACTIQUES" className="col-span-3 row-span-1">
                <div className="flex flex-row items-center mb-3">
                  <label className="block mr-2 text-base font-bold leading-4 text-indigo-800 font-ms-semi print:mt-4" htmlFor="FI_PRACTIQUES">
                    Fi Pràctiques
                  </label>
                  <ErrorMessage name="FI_PRACTIQUES" render={(message) => <span className="text-sm font-bold leading-4 text-red-600">{message}</span>} />
                </div>
                <Field
                  className={`pl-2 rounded-sm w-full appearance-none px-3 p-2 border outline-none text-grey ${
                    touched.FI_PRACTIQUES && errors.FI_PRACTIQUES ? 'border-red-200 bg-red-50 hover:border-red-400 focus:border-red-400' : 'border-gray-300 hover:border-gray-400 focus:border-gray-400'
                  }`}
                  name="FI_PRACTIQUES"
                  type="date"
                />
              </div>
          </div>
          </div>
        </div>


          <div className="grid w-full grid-cols-12 pt-4 m-0 gap-y-4">
            <div className="col-start-1 col-end-13 shadow md:col-start-3 md:col-end-11 lg:col-start-4 lg:col-end-10 xl:col-start-5 xl:col-end-9">
              <button
                alt="GUARDAR"
                title="GUARDAR"
                className="flex flex-row items-center justify-center w-full col-span-1 p-2 text-white transition duration-300 bg-green-600 border border-green-700 rounded-sm-sm hover:border-green-300 hover:bg-indigo-200 hover:text-green-700 font-ms-semi"
                type="submit"
              >
                <AiOutlineSave size={25} className="mr-2" />
                <p className="text-base font-semibold">Guardar</p>
              </button>
            </div>
          </div>
          <div className="grid w-full grid-cols-12 pt-4 m-0 gap-y-4">
            <div className="col-start-1 col-end-13 shadow md:col-start-3 md:col-end-11 lg:col-start-4 lg:col-end-10 xl:col-start-5 xl:col-end-9">
              {!isNew && (
                <button
                  alt="ELIMINAR"
                  title="ELIMINAR"
                  className="flex flex-row items-center justify-center w-full p-2 text-white transition duration-300 bg-red-600 border border-red-700 rounded-sm-sm hover:border-red-300 hover:bg-red-200 hover:text-red-700 font-ms-semi"
                  onClick={() => {
                    handleDeleteModal(values.ID_ALUMNES);
                  }}
                  type="button"
                >
                  <AiOutlineDelete size={25} className="mr-2" />
                  <p className="text-base font-semibold">Eliminar</p>
                </button>
              )}
            </div>
          </div>
          </div>
        </div>
        </Form>
      )}
    </Formik>
  );
};

StudentsFormDetailByTeacher.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  handleDelete: PropTypes.func.isRequired,
  fields: PropTypes.object,
  isNew: PropTypes.bool,
};

export default StudentsFormDetailByTeacher;
