import React, { useState } from 'react';
import PropTypes from 'prop-types';

// Filtro personalizado para rango de fechas
export function DateRangeColumnFilter({ column }) {
  // Estados locales para las fechas de inicio y fin
  const [localMinDate, setLocalMinDate] = useState('');  // Fecha mínima local
  const [localMaxDate, setLocalMaxDate] = useState('');  // Fecha máxima local
  
  // Accedemos a setFilter de la columna
  const { setFilter } = column;

  // Esta función actualiza el filtro solo cuando ambas fechas están presentes o al salir del input
  const handleFilterUpdate = () => {
    // Aplica el filtro solo si al menos una de las fechas está definida
    if (localMinDate || localMaxDate) {
      setFilter([localMinDate, localMaxDate]);  // Aplica el filtro a la tabla
    }
  };

   // Función para reiniciar los estados locales y el filtro
  const resetFilter = () => {
    setLocalMinDate('');
    setLocalMaxDate('');
    setFilter(undefined); // Limpia el filtro en react-table
  };

  // Asegúrate de que `resetFilter` esté disponible en la columna
  column.resetFilter = resetFilter;

  return (
    <div style={{ display: 'flex', flexDirection: 'column' }}>
      {/* Input para la fecha mínima */}
      <input
        value={localMinDate}
        type="date"
        onChange={(e) => setLocalMinDate(e.target.value)}  // Actualiza el estado local de la fecha mínima
        onBlur={handleFilterUpdate}  // Aplica el filtro solo al perder el foco del input
        placeholder={`Desde`}
        style={{
          marginBottom: '0.5rem',
          color: 'rgb(156, 163, 175)',
          backgroundColor: 'white',
          border: '1px solid #ccc',
          borderRadius: '2px',
          lineHeight: '1.25rem',
          fontSize: '0.875rem',
          paddingLeft: '0.25rem'
        }}
      />
      {/* Input para la fecha máxima */}
      <input
        value={localMaxDate}
        type="date"
        onChange={(e) => setLocalMaxDate(e.target.value)}  // Actualiza el estado local de la fecha máxima
        onBlur={handleFilterUpdate}  // Aplica el filtro solo al perder el foco del input
        placeholder={`Hasta`}
        style={{
          color: 'rgb(156, 163, 175)',
          backgroundColor: 'white',
          border: '1px solid #ccc',
          borderRadius: '2px',
          lineHeight: '1.25rem',
          fontSize: '0.875rem',
          paddingLeft: '0.25rem'
        }}
      />
    </div>
  );
}

// Validación de PropTypes
DateRangeColumnFilter.propTypes = {
  column: PropTypes.shape({
    setFilter: PropTypes.func.isRequired,
    resetFilter: PropTypes.func,
  }).isRequired,
};
