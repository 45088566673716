import PropTypes from 'prop-types';
import { useContext, useEffect, useState, useCallback } from 'react';
import useFetch from 'hooks/useFetch';
import { UserContext } from 'contexts/UserContext';
import { format } from 'date-fns';
import StudentsTable from 'components/ui/Table/StudentsTable';
import ColumnFilter from 'components/ui/Table/ColumnFilter';
import CheckCell from './CheckCell';
import StudentsTableBar from 'components/ui/Table/StudentsTableBar';
import { DateRangeColumnFilter } from './DateRangeColumnFilter';

export default function StudentsCompGrid({ handleClick }) {
  const { user } = useContext(UserContext);
  const [getApiCall, setApiCall] = useFetch();
  const [getTableData, setTableData] = useState([]);

  const formatDate = (date) => {
    return date ? format(new Date(date), 'dd-MM-yyyy') : '';
  };

  const columnsRole1 = [
    {
      Header: 'Codi Becari',
      accessor: 'CODI_BECARI',
      Filter: ColumnFilter,
      Type: 'codi_becari',
      Key: 'codi_becari'
    },
    {
      Header: 'DNI',
      accessor: 'DNI',
      Filter: ColumnFilter,
      Type: 'dni',
      Key: 'dni'
    },
    {
      Header: 'Cognoms, Nom',
      // accessor: (row) => `${row.COGNOM} ${row.NOM}`,
      accessor: (row) => `${row.NOM}`,
      // accessor: 'COGNOMS_NOM',
      Filter: ColumnFilter,
      Type: 'cognom_nom',
      Key: 'cognom_nom'
    },
    {
      Header: 'Num SS',
      accessor: 'NUM_SS',
      Filter: ColumnFilter,
      Type: 'num_ss',
      Key: 'num_ss'
    },
    {
      Header: 'Data Naixement',
      accessor: (row) => formatDate(row.DATA_NAIXAMENT),
      Filter: ColumnFilter,
      Type: 'data_naixement',
      Key: 'data_naixement'
    },
    {
      Header: 'Data Inici',
      accessor: (row) => formatDate(row.DATA_INICI),
      Filter: DateRangeColumnFilter,
      filter: 'dateBetween',
      Type: 'data_inici',
      Key: 'data_inici'
    },
    {
      Header: 'Data Finalització',
      accessor: (row) => formatDate(row.DATA_FINALITZACIO),
      Filter: DateRangeColumnFilter,
      filter: 'dateBetween',
      Type: 'data_finalitzacio',
      Key: 'data_finalitzacio'
    },
  ];

  const columnsRole2 = [
    {
      Header: 'Codi Centre',
      accessor: 'CODI_CENTRE',
      Filter: ColumnFilter,
      Type: 'codi_centre',
      Key: 'codi_centre'
    },
    {
      Header: 'Codi Becari',
      accessor: 'CODI_BECARI',
      Filter: ColumnFilter,
      Type: 'codi_becari',
      Key: 'codi_becari'
    },
    {
      Header: 'DNI/NIE/PASSAPORT',
      accessor: 'DNI_NIE_PASSAPORT',
      Filter: ColumnFilter,
      Type: 'dni',
      Key: 'dni',
      width: 180,
    },
    {
      Header: 'Cognoms, Nom',
      accessor: 'COGNOMS_NOM',
      Filter: ColumnFilter,
      Type: 'cognom_nom',
      Key: 'cognom_nom',
      width: 500,
    },
    {
      Header: 'Data Inici',
      accessor: (row) => formatDate(row.DATA_INICI),
      Filter: DateRangeColumnFilter,
      filter: 'dateBetween',
      Type: 'data_inici',
      Key: 'data_inici'
    },
    {
      Header: 'Inici',
      accessor: 'CHECK_TASCA_INICI',
      id: 'checks_center_inici',
      Filter: ColumnFilter,
      Type: 'check_tasca_inici',
      Key: 'check_tasca_inici',
      Cell: CheckCell
    },
    {
      Header: 'Data Finalització',
      accessor: (row) => formatDate(row.DATA_FINALITZACIO),
      Filter: DateRangeColumnFilter,
      filter: 'dateBetween',
      Type: 'data_finalitzacio',
      Key: 'data_finalitzacio'
    },
    {
      Header: 'Final',
      accessor: 'CHECK_TASCA_FINAL',
      id: 'checks_center_final',
      Filter: ColumnFilter,
      Type: 'check_tasca_final',
      Key: 'check_tasca_final',
      Cell: CheckCell
    }
  ];

   const fetchStudents = useCallback(() => {
    let apiCall;
    if (user.role_id === 1) {
      apiCall = {
        url: `students/bytchr/${user.id}`,
        method: 'GET',
        messageKo: 'Error al carregar els alumnes del professor'
      };
    } else if (user.role_id === 2) {
      apiCall = {
        url: 'students',
        method: 'GET',
        messageKo: 'Error al carregar els alumnes'
      };
    }

    setApiCall(apiCall);
  }, [user, setApiCall]);

  useEffect(() => {
    fetchStudents();
  }, [fetchStudents]);

  useEffect(() => {
    if (getApiCall.data) {
      console.log(getApiCall.data); // Verifica los datos recibidos
      setTableData(getApiCall.data);
    }
  }, [getApiCall.data]);

  const columns = user.role_id === 1 ? columnsRole1 : columnsRole2;

  return (
    <div className="overflow-x-auto shadow-md print:shadow-none">
      <StudentsTable
        data={getTableData}
        columns={columns}
        handleClick={handleClick}
        refreshData={fetchStudents} // Pass refreshData function to StudentsTable
      />
    </div>
  );
}

StudentsCompGrid.propTypes = {
  handleClick: PropTypes.func,
};

//   useEffect(() => {
//     const fetchStudents = async () => {
//       let apiCall;
//       if (user.role_id === 1) {
//         apiCall = {
//           url: `students/bytchr/${user.id}`,
//           method: 'GET',
//           messageKo: 'Error al carregar els alumnes del professor'
//         };
//       } else if (user.role_id === 2) {
//         apiCall = {
//           url: 'students',
//           method: 'GET',
//           messageKo: 'Error al carregar els alumnes'
//         };
//       }

//       setApiCall(apiCall);
//     };

//     fetchStudents();
//   }, [user, setApiCall]);

//   useEffect(() => {
//     if (getApiCall.data) {
//       console.log(getApiCall.data); // Verifica los datos recibidos
//       setTableData(getApiCall.data);
//     }
//   }, [getApiCall.data]);

//   const columns = user.role_id === 1 ? columnsRole1 : columnsRole2;

//   return (
//     <div className="overflow-x-auto shadow-md print:shadow-none">
//       <StudentsTable
//         data={getTableData}
//         columns={columns}
//         handleClick={handleClick}
//       />
//     </div>
//   );
// }

// StudentsCompGrid.propTypes = {
//   handleClick: PropTypes.func,
// };
