import React from 'react';
import PropTypes from 'prop-types';
import { saveAs } from 'file-saver';
import * as XLSX from 'xlsx';
import logoExcel from 'assets/img/logoExcel.png';
import flecha from 'assets/img/imageInvert.png';

export default function TeachersTableBar({
  previousPage,
  canPreviousPage,
  pageIndex,
  pageOptions,
  nextPage,
  canNextPage,
  pageSize,
  setPageSize,
  datos
}) {
  const rowsEmpty = datos.length === 0;

  const exportToExcel = () => {
    const worksheet = XLSX.utils.json_to_sheet(datos);
    const workbook = {
      Sheets: { 'data': worksheet },
      SheetNames: ['data']
    };
    const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
    const data = new Blob([excelBuffer], { type: 'application/octet-stream' });
    saveAs(data, 'teachers.xlsx');
  };

  return (
    <div className="grid grid-cols-12 pr-2 text-center bg-white border-t-2 border-indigo-800 print:hidden">
      <div className="flex items-center justify-start col-span-4">
        <button
          onClick={exportToExcel}
          disabled={rowsEmpty}
          hidden={rowsEmpty}
          className="font-bold rounded-lg text-white disabled:opacity-0 hover:{`handleHover`}"
          // style={{ width: '85px', marginRight: '5px', height: '85px' }}
          style={{ display: 'none' }}
        >
          <img
            src={logoExcel}
            alt="icon"
          />
        </button>
      </div>
      {/* Paginación */}
      {pageOptions.length > 1 && (
        <div className="col-span-4 place-self-center">
          <button
            onClick={() => previousPage()}
            disabled={!canPreviousPage}
            className="font-bold text-white bg-green-700 rounded-full disabled:opacity-0"
            style={{ width: '24px', marginRight: '7px', height: '24px' }}
          >
            <img
              src={flecha}
              width="15"
              height="15"
              className="p-px"
              style={{ transform: 'rotate(180deg)', margin: 'auto' }}
            />
          </button>
          <span>
            Pàgina <strong>{(pageIndex + 1).toLocaleString()}</strong> de{' '}
            <strong>{pageOptions.length.toLocaleString()} {' ('}
              {datos.length.toLocaleString()} registres{')'}
            </strong>
          </span>
          <button
            onClick={() => nextPage()}
            disabled={!canNextPage}
            className="font-bold text-white bg-green-700 rounded-full disabled:opacity-0"
            style={{ width: '24px', marginLeft: '7px', height: '24px' }}
          >
            <img
              src={flecha}
              width="15"
              height="15"
              className="p-px"
              style={{ margin: 'auto' }}
            />
          </button>
        </div>
      )}
      {/* Cantidad de registros mostrados por página */}
      {pageOptions.length > 1 && (
        <select
          className="self-center float-right col-span-4 p-1 font-semibold border-2 border-green-700 rounded outline-none bg-green-50 justify-self-end"
          value={pageSize}
          onChange={(e) => setPageSize(Number(e.target.value))}
        >
          {[25, 75, 150, 250, 500].map((pageSize) => (
            <option key={pageSize} value={pageSize}>
              {pageSize} registres
            </option>
          ))}
        </select>
      )}
    </div>
  );
}

TeachersTableBar.propTypes = {
  previousPage: PropTypes.func,
  canPreviousPage: PropTypes.bool,
  pageIndex: PropTypes.number,
  pageOptions: PropTypes.array,
  nextPage: PropTypes.func,
  canNextPage: PropTypes.bool,
  pageSize: PropTypes.number,
  setPageSize: PropTypes.func,
  datos: PropTypes.array
};
