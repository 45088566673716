// export const menuItems = [
//   {
//     title: 'Panell',
//     slug: '/',
//     active: true,
//     subMenu: false
//   },
//   // {
//   //   title: 'Alumnos',
//   //   slug: '/alumnos',
//   //   active: true,
//   //   subMenu: false
//   // },
//   // {
//   //   title: 'Fiestas',
//   //   slug: '/fiestas',
//   //   active: true,
//   //   subMenu: false
//   // },
//   {
//     title: 'ESCOLES',
//     slug: '/schools/grid',
//     active: true,
//     subMenu: false
//   },
//   {
//     title: 'PROFESSORS',
//     slug: '/teachers/grid',
//     active: true,
//     subMenu: false
//   },
//   {
//     title: 'ALUMNES',
//     slug: '/students/grid',
//     active: true,
//     subMenu: false
//   },
//   // {
//   //   title: 'Clientes',
//   //   slug: '/singles/grid',
//   //   active: true,
//   //   subMenu: false
//   // },
//   // {
//   //   title: 'Facturas',
//   //   slug: '/mails/grid',
//   //   active: true,
//   //   subMenu: false
//   // },
//   {
//     title: 'Codina',
//     slug: '',
//     active: false,
//     subMenu: false
//   },
//   {
//     title: 'Usuaris',
//     slug: '/admin/grid',
//     active: true,
//     subMenu: true
//   },
//   {
//     title: 'Cerrar',
//     slug: '/logout',
//     active: true,
//     subMenu: false
//   }
// ]

export const menuItems = [
  {
    title: 'Panell',
    slug: '/',
    active: true,
    subMenu: false,
    roles: [1, 2] // Todos los roles pueden ver este item
  },
  {
    title: 'ESCOLES',
    slug: '/schools/grid',
    active: true,
    subMenu: false,
    roles: [2]
  },
  {
    title: 'ADMINS',
    slug: '/teachers/grid',
    active: true,
    subMenu: false,
    roles: [2]
  },
  {
    title: 'ALUMNES',
    slug: '/students/grid',
    active: true,
    subMenu: false,
    roles: [1, 2] // Solo el role_id 1 puede ver este item
  },
  {
    title: 'Codina',
    slug: '',
    active: false,
    subMenu: false,
    roles: [2] // Solo el role_id 2 puede ver este item
  },
  {
    title: 'Usuaris',
    slug: '/admin/grid',
    active: true,
    subMenu: true,
    roles: [2] // Solo el role_id 2 puede ver este item
  },
  {
    title: 'TANCAR',
    slug: '/logout',
    active: true,
    subMenu: false,
    roles: [1, 2]
  }
];
