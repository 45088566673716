// PropTypes & HOOKS
import PropTypes from 'prop-types'

// FORMIK HOOK
import { Formik, Form, Field } from 'formik'
import * as Yup from 'yup'

// ASSETS
import LockIcon from 'components/icons/LockIcon'
import EmailIcon from 'components/icons/EmailIcon'

export default function LoginForm({ loading, handleSubmit }) {
  // Esquema validación formulario:
  const validationSchema = Yup.object().shape({
    email: Yup.string().email('Email invàlid').required('Introdueixi un email'),
    password: Yup.string()
      .required('Introdueixi una contrasenya')
      .min(6, 'La contrassenya és massa curta. 6 caràcters mínim')
  })

  return (
    <Formik
      initialValues={{
        email: '',
        password: ''
      }}
      validationSchema={validationSchema}
      onSubmit={(values) => {
        handleSubmit(values)
      }}
    >
      {({ errors, touched }) => (
        <Form>
          {/* Campo 'Correo': */}
          <div className="mb-4">
            <label
              className="block text-grey-darker text-md font-medium mb-2"
              htmlFor="email"
            >
              Correu
            </label>

            <div className="relative inline-block w-full text-gray-700">
              <Field
                name="email"
                id="email"
                className="pl-7 shadow appearance-none border border-gray-300 rounded w-full py-2 px-3 text-grey-darker"
                autoComplete="email"
              />

              <div className="absolute inset-y-0 left-0 flex items-center px-2 pointer-events-none">
                <EmailIcon className="w-4 h-4" />
              </div>
            </div>

            {touched.email && errors.email && (
              <p className="text-red-500 text-xs italic">{errors.email}</p>
            )}
          </div>

          {/* Campo 'Contraseña': */}
          <div className="mb-4">
            <label
              className="block text-grey-darker text-md font-medium mb-2"
              htmlFor="password"
            >
              Contrasenya
            </label>

            <div className="relative inline-block w-full text-gray-700">
              <Field
                type="password"
                name="password"
                id="password"
                autoComplete="current-password"
                className="pl-7 shadow appearance-none border border-gray-300 rounded w-full py-2 px-3 text-grey-darker"
              />

              <div className="absolute inset-y-0 left-0 flex items-center px-2 pointer-events-none">
                <LockIcon className="w-4 h-4" />
              </div>
            </div>

            {touched.password && errors.password && (
              <p className="text-red-500 text-xs italic">{errors.password}</p>
            )}
          </div>

          {/* Botón 'Iniciar Sesión': */}
          <div className="mb-4">
            <button
              type="submit"
              className="h-10 px-5 my-2 w-full text-white transition-colors duration-150 bg-primary rounded-lg focus:shadow-outline hover:bg-secondary"
              disabled={loading && 'disabled'}
            >
              Iniciar Sessió
            </button>
          </div>
        </Form>
      )}
    </Formik>
  )
}

LoginForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  loading: PropTypes.bool
}
