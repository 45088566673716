import PropTypes from 'prop-types';
import { useEffect, useState, useContext } from 'react';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import Select from 'react-select';
import * as Yup from 'yup';

// CONTEXTS
import { ModalContext } from 'contexts/ModalContext';

// ASSETS
import { AiOutlineSave, AiOutlineDelete } from 'react-icons/ai';
import axios from 'axios';

const StudentsFormDetail = ({ fields, handleSubmit, handleDelete, isNew }) => {
  const [schoolOptions, setSchoolOptions] = useState([]);
  const [teacherOptions, setTeacherOptions] = useState([]);
  const [selectedSchool, setSelectedSchool] = useState(fields.ID_ESCOLA || null);
  const [ssPart1, setSsPart1] = useState(fields.NUM_SS ? fields.NUM_SS.slice(0, 2) : ''); // First 2 digits
  const [ssPart2, setSsPart2] = useState(fields.NUM_SS ? fields.NUM_SS.slice(2, 10) : ''); // Next 8 digits
  const [ssPart3, setSsPart3] = useState(fields.NUM_SS ? fields.NUM_SS.slice(10, 12) : ''); // Last 2 digits


  const { openModal } = useContext(ModalContext);

  useEffect(() => {
  if (fields.NUM_SS) {
    setSsPart1(fields.NUM_SS.slice(0, 2)); // First 2 digits
    setSsPart2(fields.NUM_SS.slice(2, 10)); // Next 8 digits
    setSsPart3(fields.NUM_SS.slice(10, 12)); // Last 2 digits
  }
}, [fields.NUM_SS]);

 

  const handleDeleteModal = (id) => {
    const modalData = {
      id,
      handleDelete,
      type: 'DELETE',
    };
    openModal(modalData);
  };

  useEffect(() => {
    const fetchSchools = async () => {
      try {
        // const response = await axios.get('https://api-codina-af1a88eea621.herokuapp.com/schools/');
        // const response = await axios.get('http://localhost:5000/schools/');
        const response = await axios.get(process.env.REACT_APP_API_URL + '/schools/');
        const options = response.data.map((school) => ({
          value: school.ID_ESCOLA,
          label: school.CODI,
        }));
        setSchoolOptions(options);
      } catch (error) {
        console.error('Error fetching schools:', error);
      }
    };

    const fetchTeachers = async () => {
      try {
        // const response = await axios.get('https://api-codina-af1a88eea621.herokuapp.com/teachers');
        // const response = await axios.get('http://localhost:5000/teachers');
        const response = await axios.get( process.env.REACT_APP_API_URL + '/teachers');
        setTeacherOptions(response.data);
      } catch (error) {
        console.error('Error fetching teachers:', error);
      }
    };

    fetchSchools();
    fetchTeachers();
  }, []);

  const formatDate = (date) => {
    if (!date) return null;
    const d = new Date(date);
    const month = `0${d.getMonth() + 1}`.slice(-2);
    const day = `0${d.getDate()}`.slice(-2);
    return `${d.getFullYear()}-${month}-${day}`;
  };

  const initialValues = {
    ...fields,
    DATA_NAIXAMENT: formatDate(fields.DATA_NAIXAMENT),
    INICI_PRACTIQUES: formatDate(fields.INICI_PRACTIQUES),
    FI_PRACTIQUES: formatDate(fields.FI_PRACTIQUES),
    INICI_CHECK: isNew ? 2 : fields.INICI_CHECK !== undefined ? fields.INICI_CHECK : 0,
    FI_CHECK: isNew ? 2 : fields.FI_CHECK !== undefined ? fields.FI_CHECK : 0,
  };

    const dniRegex = /^[0-9]{8}[A-Z]$/; // Ejemplo: 12345678A
    const nieRegex = /^[XYZ][0-9]{7}[A-Z]$/; // Ejemplo: X1234567A
    const passportRegex = /^[A-Z0-9]{5,9}$/; // Ejemplo: A123456

  return (
    <Formik
      // enableReinitialize={true}
      // initialValues={initialValues}
      // onSubmit={(values) => {
      //   handleSubmit(values);
      // }}
      enableReinitialize={true}
      initialValues={initialValues}
      onSubmit={(values) => {
        // Concatenar las partes del número de la Seguridad Social
        const fullSSNumber = `${ssPart1}${ssPart2}${ssPart3}`;
        const updatedValues = {
          ...values,
          NUM_SS: fullSSNumber, // Aquí se envía el número completo al backend
        };
        handleSubmit(updatedValues);
      }}
    
      validationSchema={Yup.object({
        NOM: Yup.string()
          .matches(/^[a-zA-ZáéíóúÁÉÍÓÚñÑ\s]*$/, 'Solo se permiten letras y espacios')
          .required('Required'),
        // COGNOM: Yup.string()
        //   .matches(/^[a-zA-ZáéíóúÁÉÍÓÚñÑ\s]*$/, 'Solo se permiten letras y espacios')
        //   .required('Required'),
        DNI: Yup.string()
          .required('Required')
          .test('dni-nie-pass', 'Debe ser un DNI, NIE o Pasaporte válido', value => {
            return dniRegex.test(value) || nieRegex.test(value) || passportRegex.test(value);
          }),
        DATA_NAIXAMENT: Yup.date().required('Requerido'),
        ID_ESCOLA: Yup.number().required('Requerido'),
        ID_PROFESSOR: Yup.number().required('Requerido'),
        INICI_PRACTIQUES: Yup.date().nullable(),
        FI_PRACTIQUES: Yup.date().nullable(),
        INICI_CHECK: Yup.number()
          .oneOf([0, 1, 2])
          .required('Requerido'),
        FI_CHECK: Yup.number()
          .oneOf([0, 1, 2])
          .required('Requerido')
      })}

    >
      {({ values, errors, touched, setFieldValue }) => (
        <Form className="flex flex-col bg-blue-100 border-2 border-indigo-800 rounded-lg p-6">
          <div className="grid grid-cols-12 gap-6">
            <div className="col-span-12">
              <h2 className="text-lg font-semibold text-indigo-900 mb-4">Informació Bàsica</h2>
              <div className="grid grid-cols-12 gap-6 bg-white border-2 border-blue-200 p-4 rounded-lg">
                {/* <div className="col-span-4">
                  <label className="block text-base font-bold text-indigo-800 mb-1" htmlFor="CODI_BECARI">Codi Becari</label>
                  <Field className="w-full p-2 border rounded-sm border-gray-300 hover:border-gray-400 focus:border-gray-400" name="CODI_BECARI" type="text" />
                  <ErrorMessage name="CODI_BECARI" component="div" className="text-red-600 text-sm" />
                </div> */}
                <div className="col-span-8">
                  <label className="block text-base font-bold text-indigo-800 mb-1" htmlFor="NOM">Cognoms i Nom</label>
                  <Field className="w-full p-2 border rounded-sm border-gray-300 hover:border-gray-400 focus:border-gray-400" name="NOM" type="text" />
                  <ErrorMessage name="NOM" component="div" className="text-red-600 text-sm" />
                </div>
                {/* <div className="col-span-4">
                  <label className="block text-base font-bold text-indigo-800 mb-1" htmlFor="COGNOM">Cognoms</label>
                  <Field className="w-full p-2 border rounded-sm border-gray-300 hover:border-gray-400 focus:border-gray-400" name="COGNOM" type="text" />
                  <ErrorMessage name="COGNOM" component="div" className="text-red-600 text-sm" />
                </div> */}
                <div className="col-span-4">
                  <label className="block text-base font-bold text-indigo-800 mb-1" htmlFor="DNI">DNI</label>
                  <Field className="w-full p-2 border rounded-sm border-gray-300 hover:border-gray-400 focus:border-gray-400" name="DNI" type="text" />
                  <ErrorMessage name="DNI" component="div" className="text-red-600 text-sm" />
                </div>
                <div className="col-span-4">
                  <label className="block text-base font-bold text-indigo-800 mb-1" htmlFor="DATA_NAIXAMENT">Data Naixement</label>
                  <Field className="w-full p-2 border rounded-sm border-gray-300 hover:border-gray-400 focus:border-gray-400" name="DATA_NAIXAMENT" type="date" />
                  <ErrorMessage name="DATA_NAIXAMENT" component="div" className="text-red-600 text-sm" />
                </div>
                {/* <div className="col-span-4">
                  <label className="block text-base font-bold text-indigo-800 mb-1" htmlFor="NUM_SS">Num SS</label>
                  <Field className="w-full p-2 border rounded-sm border-gray-300 hover:border-gray-400 focus:border-gray-400" name="NUM_SS" type="text" />
                  <ErrorMessage name="NUM_SS" component="div" className="text-red-600 text-sm" />
                </div> */}
                <div className="col-span-2">
                  <label className="block text-base font-bold text-indigo-800 mb-1" htmlFor="NUM_SS">Numero Seguretat Social</label>
                  <Field
                    className="w-full p-2 border rounded-sm border-gray-300 hover:border-gray-400 focus:border-gray-400"
                    name="SS_PART1"
                    minLength="2"
                    maxLength="2"
                    placeholder="00"
                    value={ssPart1}
                    onChange={(e) => setSsPart1(e.target.value)}
                  />
                </div>
                <div className="col-span-2">
                  <label className="block text-base font-bold text-indigo-800 mb-1" htmlFor="NUM_SS">00000000</label>
                  <Field
                    className="w-full p-2 border rounded-sm border-gray-300 hover:border-gray-400 focus:border-gray-400"
                    name="SS_PART2"
                    minLength="8"
                    maxLength="8"
                    placeholder="00000000"
                    value={ssPart2}
                    onChange={(e) => setSsPart2(e.target.value)}
                  />
                </div>
                <div className="col-span-2">
                  <label className="block text-base font-bold text-indigo-800 mb-1" htmlFor="NUM_SS">00</label>
                  <Field
                    className="w-full p-2 border rounded-sm border-gray-300 hover:border-gray-400 focus:border-gray-400"
                    name="SS_PART3"
                    minLength="2"
                    maxLength="2"
                    placeholder="00"
                    value={ssPart3}
                    onChange={(e) => setSsPart3(e.target.value)}
                  />
                </div>
              </div>
            </div>
            
            <div className="col-span-12">
              <h2 className="text-lg font-semibold text-indigo-900 mb-4">Escola</h2>
              <div className="grid grid-cols-12 gap-6 bg-white border-2 border-blue-200 p-4 rounded-lg">
                <div className="col-span-6">
                  <label className="block text-base font-bold text-indigo-800 mb-1" htmlFor="ID_ESCOLA">Escola</label>
                  <Select
                    className="w-full rounded-sm text-grey-dark"
                    name="ID_ESCOLA"
                    onChange={(e) => {
                      setFieldValue('ID_ESCOLA', e.value);
                      setSelectedSchool(e.value);
                      setFieldValue('ID_PROFESSOR', null);
                    }}
                    placeholder="Seleccionar..."
                    options={schoolOptions}
                    value={schoolOptions.find((option) => option.value === values.ID_ESCOLA)}
                    styles={{
                      control: (baseStyles, state) => ({
                        ...baseStyles,
                        padding: '2px 0px 2px 0px',
                        border: state.isFocused ? '1px solid #9ca3af' : '1px solid #d1d5db',
                        boxShadow: state.isFocused ? 'none' : 'none',
                        '&:hover': {
                          border: '1px solid #9ca3af',
                        },
                        borderRadius: '0.125rem',
                        cursor: 'pointer',
                      }),
                      option: (baseStyles, state) => ({
                        ...baseStyles,
                        color: '#333333',
                        backgroundColor: state.isFocused && '#d1d5db',
                        '&:hover': {
                          backgroundColor: '#f3f4f6',
                        },
                        cursor: 'pointer',
                      }),
                    }}
                  />
                  <ErrorMessage name="ID_ESCOLA" component="div" className="text-red-600 text-sm" />
                </div>
                <div className="col-span-6">
                  <label className="block text-base font-bold text-indigo-800 mb-1" htmlFor="ID_PROFESSOR">Professor</label>
                   <Select
                      className="w-full rounded-sm text-grey-dark"
                      name="ID_PROFESSOR"
                      onChange={(e) => setFieldValue('ID_PROFESSOR', e.value)}
                      placeholder="Seleccionar..."
                      options={teacherOptions
                        .filter((teacher) => teacher.ID_ESCOLA === selectedSchool)
                        .map((teacher) => ({
                          value: teacher.ID_PROFESSOR,
                          label: `${teacher.NOM} ${teacher.COGNOM}`,
                        }))}
                      value={
                        teacherOptions
                          .filter((teacher) => teacher.ID_ESCOLA === selectedSchool)
                          .map((teacher) => ({
                            value: teacher.ID_PROFESSOR,
                            label: `${teacher.NOM} ${teacher.COGNOM}`,
                          }))
                          .find((option) => option.value === values.ID_PROFESSOR) || null
                      }
                      getOptionLabel={(option) => option.label}  // Mostrar el nombre completo del profesor
                      getOptionValue={(option) => option.value}  // Usar el ID del profesor como valor
                      styles={{
                        control: (baseStyles, state) => ({
                          ...baseStyles,
                          padding: '2px 0px 2px 0px',
                          border: state.isFocused ? '1px solid #9ca3af' : '1px solid #d1d5db',
                          boxShadow: state.isFocused ? 'none' : 'none',
                          '&:hover': {
                            border: '1px solid #9ca3af',
                          },
                          borderRadius: '0.125rem',
                          cursor: 'pointer',
                        }),
                        option: (baseStyles, state) => ({
                          ...baseStyles,
                          color: '#333333',
                          backgroundColor: state.isFocused && '#d1d5db',
                          '&:hover': {
                            backgroundColor: '#f3f4f6',
                          },
                          cursor: 'pointer',
                        }),
                      }}
                    />
                  <ErrorMessage name="ID_PROFESSOR" component="div" className="text-red-600 text-sm" />
                </div>
              </div>
            </div>

            <div className="col-span-12">
              <h2 className="text-lg font-semibold text-indigo-900 mb-4">Pràctiques</h2>
              <div className="grid grid-cols-12 gap-6 bg-white border-2 border-blue-200 p-4 rounded-lg">
                <div className="col-span-6">
                  <label className="block text-base font-bold text-indigo-800 mb-1" htmlFor="INICI_PRACTIQUES">Inici Pràctiques</label>
                  <Field className="w-full p-2 border rounded-sm border-gray-300 hover:border-gray-400 focus:border-gray-400" name="INICI_PRACTIQUES" type="date" />
                  <ErrorMessage name="INICI_PRACTIQUES" component="div" className="text-red-600 text-sm" />
                
                
                  <label className="block text-base font-bold text-indigo-800 mb-1" htmlFor="FI_PRACTIQUES">Fi Pràctiques</label>
                  <Field className="w-full p-2 border rounded-sm border-gray-300 hover:border-gray-400 focus:border-gray-400" name="FI_PRACTIQUES" type="date" />
                  <ErrorMessage name="FI_PRACTIQUES" component="div" className="text-red-600 text-sm" />
                </div>
                <div className="col-span-6">
                  <label className="block text-base font-bold text-indigo-800 mb-1" htmlFor="INICI_CHECK">Check Tasca Inici</label>
                  <Field as="select" name="INICI_CHECK" className="w-full p-2 border rounded-sm border-gray-300 hover:border-gray-400 focus:border-gray-400">
                    <option value={1}>Sí</option>
                    <option value={0}>No</option>
                    <option value={2}>Pendent</option>
                  </Field>
                  <ErrorMessage name="INICI_CHECK" component="div" className="text-red-600 text-sm" />
                
                
                  <label className="block text-base font-bold text-indigo-800 mb-1" htmlFor="FI_CHECK">Check Tasca Final</label>
                  <Field as="select" name="FI_CHECK" className="w-full p-2 border rounded-sm border-gray-300 hover:border-gray-400 focus:border-gray-400">
                    <option value={1}>Sí</option>
                    <option value={0}>No</option>
                    <option value={2}>Pendent</option>
                  </Field>
                  <ErrorMessage name="FI_CHECK" component="div" className="text-red-600 text-sm" />
                </div>
              </div>
            </div>

            <div className="col-span-12">
              <h2 className="text-lg font-semibold text-indigo-900 mb-4">Observacions</h2>
              <div className="grid grid-cols-12 gap-6 bg-white border-2 border-blue-200 p-4 rounded-lg">
                <div className="col-span-12">
                  <label className="block text-base font-bold text-indigo-800 mb-1" htmlFor="OBSERVACIONS">Comentaris</label>
                  <Field
                    as="textarea"
                    className="w-full p-2 border rounded-sm border-gray-300 hover:border-gray-400 focus:border-gray-400"
                    name="OBSERVACIONS"
                  />
                  <ErrorMessage name="OBSERVACIONS" component="div" className="text-red-600 text-sm" />
                </div>
              </div>
            </div>
          </div>
                    
          <div className="grid w-full grid-cols-12 pt-4 m-0 gap-y-4">
            <div className="col-start-1 col-end-13 shadow md:col-start-3 md:col-end-11 lg:col-start-4 lg:col-end-10 xl:col-start-5 xl:col-end-9">
              <button
                alt="GUARDAR"
                title="GUARDAR"
                className="flex flex-row items-center justify-center w-full col-span-1 p-2 text-white transition duration-300 bg-green-600 border border-green-700 rounded-sm-sm hover:border-green-300 hover:bg-indigo-200 hover:text-green-700 font-ms-semi"
                type="submit"
              >
                <AiOutlineSave size={25} className="mr-2" />
                <p className="text-base font-semibold">Guardar</p>
              </button>
            </div>

            <div className="col-start-1 col-end-13 shadow md:col-start-3 md:col-end-11 lg:col-start-4 lg:col-end-10 xl:col-start-5 xl:col-end-9">
              {!isNew && (
                <button
                  alt="ELIMINAR"
                  title="ELIMINAR"
                  className="flex flex-row items-center justify-center w-full p-2 text-white transition duration-300 bg-red-600 border border-red-700 rounded-sm-sm hover:border-red-300 hover:bg-red-200 hover:text-red-700 font-ms-semi"
                  onClick={() => {
                    handleDeleteModal(values.ID_ALUMNES);
                  }}
                  type="button"
                >
                  <AiOutlineDelete size={25} className="mr-2" />
                  <p className="text-base font-semibold">Eliminar</p>
                </button>
              )}
            </div>
          </div>
        </Form>
      )}
    </Formik>
  );
};

StudentsFormDetail.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  handleDelete: PropTypes.func.isRequired,
  fields: PropTypes.object,
  isNew: PropTypes.bool,
};

export default StudentsFormDetail;
