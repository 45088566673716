import React, { useEffect, useState, useCallback, useContext } from 'react';
import { useRoute } from 'wouter';
import { TitleContext } from 'contexts/TitleContext';
import useFetch from 'hooks/useFetch';
import { toast } from 'react-toastify';
import dayjs from 'dayjs';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { ImArrowLeft2 } from 'react-icons/im';

const StudentsCalendarPage = () => {
  const [match, params] = useRoute('/calendar/:userId/:idEscola/:idPractiques');
  const { setTitle } = useContext(TitleContext);
  const [studentData, setStudentData] = useState(null);
  const [practiceData, setPracticeData] = useState({ INICI_PRACTIQUES: null, FI_PRACTIQUES: null, ID_CURS: 1 });
  const [monthData, setMonthData] = useState(Array(12).fill(0));
  const [errorMessage, setErrorMessage] = useState(''); // Estado para el mensaje de error
  const [isButtonDisabled, setIsButtonDisabled] = useState(false); // Estado para controlar el botón

  const [getApiCallGetStudent, setApiCallGetStudent] = useFetch();
  const [getApiCallGetPractices, setApiCallGetPractices] = useFetch();
  const [getApiCallGetMonthPractices, setApiCallGetMonthPractices] = useFetch();
  const [postApiCallInsertMonthPractices, setApiCallInsertMonthPractices] = useFetch();
  const [putApiCallUpdateMonthPractices, setApiCallUpdateMonthPractices] = useFetch();

  const fetchStudentData = useCallback(async () => {
    const getApiCall = {
      url: `students/${params.userId}`,
      method: 'GET',
      successMessage: null,
      failureMessage: 'Error de càrrega!'
    };
    setApiCallGetStudent(getApiCall);
  }, [params.userId, setApiCallGetStudent]);

  const fetchPracticeData = useCallback(async () => {
    const getApiCall = {
      url: `students/practices/${params.userId}`,
      method: 'GET',
      messageOk: null,
      messageKo: 'Error al consultar pràctiques',
    };
    setApiCallGetPractices(getApiCall);
  }, [params.userId, setApiCallGetPractices]);

  const fetchMonthPractices = useCallback(async () => {
    const getApiCall = {
      url: `practices/months/${params.userId}/${params.idPractiques}`,
      method: 'GET',
      messageOk: null,
      messageKo: 'Error al consultar les pràctiques mensuals',
    };
    setApiCallGetMonthPractices(getApiCall);
  }, [params.userId, params.idPractiques, setApiCallGetMonthPractices]);

  useEffect(() => {
    if (match) {
      fetchStudentData();
      fetchPracticeData();
      fetchMonthPractices();
    }
  }, [match, fetchStudentData, fetchPracticeData, fetchMonthPractices]);

  useEffect(() => {
    if (getApiCallGetStudent.data) {
      setStudentData(getApiCallGetStudent.data);
    }
  }, [getApiCallGetStudent.data]);

  useEffect(() => {
    if (getApiCallGetPractices.data && getApiCallGetPractices.data.length > 0) {
      setPracticeData(getApiCallGetPractices.data[0]);
    }
  }, [getApiCallGetPractices.data]);

  useEffect(() => {
    if (getApiCallGetMonthPractices.data && getApiCallGetMonthPractices.data.length > 0) {
      const monthDataFromAPI = getApiCallGetMonthPractices.data[0];
      setMonthData([
        monthDataFromAPI.January, monthDataFromAPI.February, monthDataFromAPI.March,
        monthDataFromAPI.April, monthDataFromAPI.May, monthDataFromAPI.June,
        monthDataFromAPI.July, monthDataFromAPI.August, monthDataFromAPI.September,
        monthDataFromAPI.October, monthDataFromAPI.November, monthDataFromAPI.December,
      ]);
    }
  }, [getApiCallGetMonthPractices.data]);

  const handleSaveMonthData = async (e) => {
    e.preventDefault();
    const isNew = !getApiCallGetMonthPractices.data || getApiCallGetMonthPractices.data.length === 0;
    const apiCall = {
      url: `practices/months/${params.userId}/${params.idPractiques}`,
      method: isNew ? 'POST' : 'PUT',
      body: {
        January: monthData[0], February: monthData[1], March: monthData[2],
        April: monthData[3], May: monthData[4], June: monthData[5],
        July: monthData[6], August: monthData[7], September: monthData[8],
        October: monthData[9], November: monthData[10], December: monthData[11],
      },
      messageOk: 'Práctiques mensuals actualitzades',
      messageKo: 'Error al actualitzar les pràctiques mensuals',
    };

    await (isNew ? setApiCallInsertMonthPractices(apiCall) : setApiCallUpdateMonthPractices(apiCall));
    fetchMonthPractices();
  };

  const handleMonthChange = (index, value) => {
    const newMonthData = [...monthData];
    newMonthData[index] = value;
    setMonthData(newMonthData);

    const invalidData = newMonthData.some(val => val > 31);
    if (invalidData) {
      setErrorMessage('Només es permeten fins a 31 dies.');
      setIsButtonDisabled(true);
    } else {
      setErrorMessage('');
      setIsButtonDisabled(false);
    }
  };

  useEffect(() => {
    setTitle({
      // name: `Calendari de ${studentData?.NOM || ''} ${studentData?.COGNOM || ''}`,
      name: `Calendari de ${studentData?.NOM || ''}`,
      buttons: [
        {
          id: 'btnBack',
          name: (
            <span className="flex flex-row items-center w-full">
              <span className="pl-3 pr-1">
                <ImArrowLeft2 size={20} />
              </span>
              <span className="pl-1 pr-3">Tornar</span>
            </span>
          ),
          link: `/students/grid`,
        },
      ],
    });
  }, [setTitle, studentData]);

  const months = ['Gener', 'Febrer', 'Març', 'Abril', 'Maig', 'Juny', 'Juliol', 'Agost', 'Setembre', 'Octubre', 'Novembre', 'Desembre'];

  if (!match) return <div>No se va trobar la pàgina</div>;

  return (
    <div className="p-4">
      {getApiCallGetStudent.loading || getApiCallGetPractices.loading || getApiCallGetMonthPractices.loading ? (
        <p>Carregant dades...</p>
      ) : getApiCallGetStudent.error || getApiCallGetPractices.error || getApiCallGetMonthPractices.error ? (
        <p>Error al carregar les dades: {getApiCallGetStudent.error?.message || getApiCallGetPractices.error?.message || getApiCallGetMonthPractices.error?.message}</p>
      ) : (
        <div>
          {/* <h1 className="text-2xl font-bold mb-4">Calendari de {studentData?.NOM} {studentData?.COGNOM}</h1> */}
          <div className="flex space-x-8 items-start">
            <form onSubmit={handleSaveMonthData} className="space-y-4 w-1/6">
              <div className="col-span-3 row-span-1">
                <div className="flex flex-row items-center mb-3">
                  <label
                    className="block mr-2 text-base font-bold leading-4 text-indigo-800"
                    htmlFor="INICI_PRACTIQUES"
                  >
                    Inici de pràctiques
                  </label>
                </div>
                <DatePicker
                  selected={practiceData.INICI_PRACTIQUES ? new Date(practiceData.INICI_PRACTIQUES) : null}
                  onChange={(date) => setPracticeData({ ...practiceData, INICI_PRACTIQUES: date })}
                  dateFormat="dd/MM/yyyy"
                  className={`pl-2 rounded-sm w-full appearance-none px-3 py-2 border outline-none text-grey ${
                    practiceData.INICI_PRACTIQUES ? 'border-gray-300 hover:border-gray-400 focus:border-gray-400' : 'border-red-200 bg-red-50 hover:border-red-400 focus:border-red-400'
                  }`}
                  placeholderText="dd/mm/aaaa"
                  disabled
                />
              </div>
              <div className="col-span-3 row-span-1">
                <div className="flex flex-row items-center mb-3">
                  <label
                    className="block mr-2 text-base font-bold leading-4 text-indigo-800"
                    htmlFor="FI_PRACTIQUES"
                  >
                    Final de pràctiques
                  </label>
                </div>
                <DatePicker
                  selected={practiceData.FI_PRACTIQUES ? new Date(practiceData.FI_PRACTIQUES) : null}
                  onChange={(date) => setPracticeData({ ...practiceData, FI_PRACTIQUES: date })}
                  dateFormat="dd/MM/yyyy"
                  className={`pl-2 rounded-sm w-full appearance-none px-3 py-2 border outline-none text-grey ${
                    practiceData.FI_PRACTIQUES ? 'border-gray-300 hover:border-gray-400 focus:border-gray-400' : 'border-red-200 bg-red-50 hover:border-red-400 focus:border-red-400'
                  }`}
                  placeholderText="dd/mm/aaaa"
                  disabled
                />
              </div>
              {/* <div className="col-span-3 row-span-1">
                <div className="flex flex-row items-center mb-3">
                  <label
                    className="block mr-2 text-base font-bold leading-4 text-indigo-800"
                    htmlFor="ID_CURS"
                  >
                    Curs
                  </label>
                </div>
                <input
                  type="number"
                  value={practiceData.ID_CURS}
                  onChange={(e) => setPracticeData({ ...practiceData, ID_CURS: e.target.value })}
                  className={`pl-2 rounded-sm w-full appearance-none px-3 py-2 border outline-none text-grey ${
                    practiceData.ID_CURS ? 'border-gray-300 hover:border-gray-400 focus:border-gray-400' : 'border-red-200 bg-red-50 hover:border-red-400 focus:border-red-400'
                  }`}
                />
              </div>
              <button
                type="submit"
                className="w-full mt-2 inline-flex items-center justify-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-indigo-800 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
              >
                Guardar dates
              </button> */}
            </form>
            <div className="w-5/6">
              <div className="grid grid-cols-3 gap-4">
                {months.map((month, index) => (
                  <div key={index} className="col-span-1">
                    <label
                      className="block text-base font-bold leading-4 text-indigo-800 mb-2"
                      htmlFor={`month-${index}`}
                    >
                      {month}
                    </label>
                    <input
                      type="number"
                      id={`month-${index}`}
                      value={monthData[index]}
                      onChange={(e) => handleMonthChange(index, e.target.value)}
                      className="pl-2 rounded-sm w-full appearance-none px-3 py-2 border outline-none text-grey border-gray-300 hover:border-gray-400 focus:border-gray-400"
                      placeholder="0-31"
                      min="0"
                      max="31"
                    />
                  </div>
                ))}
              </div>
              {errorMessage && (
                <p className="text-red-500 mt-2">{errorMessage}</p>
              )}
              <button
                type="submit"
                onClick={handleSaveMonthData}
                disabled={isButtonDisabled}
                className={`w-full mt-2 inline-flex items-center justify-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-indigo-800 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 ${
                  isButtonDisabled ? 'opacity-50 cursor-not-allowed' : ''
                }`}
              >
                Guardar dades
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default StudentsCalendarPage;
