// PropTypes & HOOKS
import PropTypes from 'prop-types'
import { useContext } from 'react'

// CONTEXT
import { ModalContext } from 'contexts/ModalContext'

// ICONS
import { MdClose } from 'react-icons/md'
import { BsTrash, BsExclamationTriangle } from 'react-icons/bs'

// export default function DeleteModal({ params }) {
//   /**
//    * @param isModalOpen  - abrir modal
//    * @param closeModal   - cerrar modal
//    * @param id           - id del elemento que queremos borrar
//    * @param handleDelete - función para eliminar
//    */
//   const { isModalOpen, closeModal } = useContext(ModalContext)
//   const { id, handleDelete } = useContext(ModalContext).getModalData

//   // Bloqueamos el scroll:
//   const scrollTop = window.pageYOffset || document.documentElement.scrollTop
//   const scrollLeft = window.pageXOffset || document.documentElement.scrollLeft
//   window.onscroll = function () {
//     window.scrollTo(scrollLeft, scrollTop)
//   }

//   const handleConfirm = () => {
//     closeModal()
//     window.onscroll = function () {}
//     handleDelete(id)
//   }

//   return (
//     isModalOpen && (
//       <>
//         <div
//           className="w-screen h-screen fixed bg-black bg-opacity-75 top-0 left-0 z-50 flex justify-center items-center"
//           onClick={() => {
//             closeModal()
//             window.onscroll = function () {}
//           }}
//         />

//         <div className="w-1/2 m-auto justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
//           <div className="relative w-full my-6 mx-auto max-w-6xl">
//             <div
//               className="w-full border-2 border-red-600 rounded-md shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none
//                 divide-y divide-dashed divide-red-200"
//             >
//               {/* CABECERA */}
//               <div className="flex items-center justify-between p-5">
//                 <h3 className="text-2xl font-semibold">
//                   <b>ELIMINAR</b> Registre
//                 </h3>
//                 <button
//                   className="transition duration-300 
//                     border-red-600 bg-red-500 text-white hover:border-red-400 hover:bg-red-300 hover:text-red-600
//                     font-ms-semi py-1 px-1 border-2 rounded 
//                     flex justify-center items-center"
//                   type="button"
//                   onClick={() => {
//                     closeModal()
//                     window.onscroll = function () {}
//                   }}
//                 >
//                   <MdClose size={25} title="Cerrar" />
//                 </button>
//               </div>

//               {/* CUERPO */}
//               <div className="w-full flex flex-col justify-center items-center px-6 py-12 justify-between">
//                 <p className="text-lg font-bold text-red-700">
//                   Esteu segur que voleu{' '}
//                   <u className="text-red-900">ELIMINAR</u> el registre?
//                 </p>
//               </div>

//               {/* PIE */}
//               <div className="grid grid-cols-2 p-4 border-t border-solid border-slate-200 rounded-b">
//                 <div>
//                   <div className="col-span-1 flex justify-start items-center text-center">
//                     <div
//                       className="inline-flex rounded p-2 bg-amber-600 items-center text-amber-100 leading-none"
//                       role="alert"
//                       title="¡Cuidado!"
//                     >
//                       <span className="flex rounded bg-amber-500 uppercase px-2 py-1 text-xs font-bold mr-3">
//                         <BsExclamationTriangle size={25} />
//                       </span>
//                       <span className="fotn-semibold mr-2 text-left flex-auto">
//                         ATENCIÓ! L`acció no es pot desfer.
//                       </span>
//                     </div>
//                   </div>
//                 </div>

//                 <div className="col-span-1 flex justify-end items-center ">
//                   <button
//                     className="transition duration-300 
//                       border-red-600 bg-red-500 text-white hover:border-red-400 hover:bg-red-300 hover:text-red-600
//                       font-ms-semi py-2 px-6 border-2 rounded 
//                       flex justify-center items-center"
//                     type="button"
//                     onClick={() => handleConfirm()}
//                   >
//                     <BsTrash size={30} title="Eliminar" />
//                   </button>
//                 </div>
//               </div>
//             </div>
//           </div>
//         </div>
//       </>
//     )
//   )
// }

export default function DeleteModal() {
  const { isModalOpen, closeModal, getModalData } = useContext(ModalContext)
  const { id, handleDelete, message } = getModalData || {}

  const handleConfirm = () => {
    closeModal()
    window.onscroll = function () {}
    handleDelete(id)
  }

  return (
    isModalOpen && (
      <>
        <div
          className="w-screen h-screen fixed bg-black bg-opacity-75 top-0 left-0 z-50 flex justify-center items-center"
          onClick={() => {
            closeModal()
            window.onscroll = function () {}
          }}
        />

        <div className="w-1/2 m-auto justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
          <div className="relative w-full my-6 mx-auto max-w-6xl">
            <div
              className="w-full border-2 border-red-600 rounded-md shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none divide-y divide-dashed divide-red-200"
            >
              {/* CABECERA */}
              <div className="flex items-center justify-between p-5">
                <h3 className="text-2xl font-semibold">
                  <b>ELIMINAR</b>
                </h3>
                <button
                  className="transition duration-300 border-red-600 bg-red-500 text-white hover:border-red-400 hover:bg-red-300 hover:text-red-600 font-ms-semi py-1 px-1 border-2 rounded flex justify-center items-center"
                  type="button"
                  onClick={() => {
                    closeModal()
                    window.onscroll = function () {}
                  }}
                >
                  <MdClose size={25} title="Cerrar" />
                </button>
              </div>

              {/* CUERPO */}
              <div className="w-full flex flex-col justify-center items-center px-6 py-12 justify-between">
                <p className="text-lg font-bold text-red-700">
                  {message ? (
                    <>{message}</>  // Mensaje personalizado
                  ) : (
                    <>Estàs segur que vols <u className="text-red-900">ELIMINAR</u> aquest registre?</>  // Mensaje genérico
                  )}
                </p>
              </div>

              {/* PIE */}
              <div className="grid grid-cols-2 p-4 border-t border-solid border-slate-200 rounded-b">
                <div>
                  <div className="col-span-1 flex justify-start items-center text-center">
                    <div
                      className="inline-flex rounded p-2 bg-amber-600 items-center text-amber-100 leading-none"
                      role="alert"
                      title="¡Cuidado!"
                    >
                      <span className="flex rounded bg-amber-500 uppercase px-2 py-1 text-xs font-bold mr-3">
                        <BsExclamationTriangle size={25} />
                      </span>
                      <span className="font-semibold mr-2 text-left flex-auto">
                        ATENCIÓ: Aquesta acció no es pot desfer!
                      </span>
                    </div>
                  </div>
                </div>

                <div className="col-span-1 flex justify-end items-center">
                  <button
                    className="transition duration-300 border-red-600 bg-red-500 text-white hover:border-red-400 hover:bg-red-300 hover:text-red-600 font-ms-semi py-2 px-6 border-2 rounded flex justify-center items-center"
                    type="button"
                    onClick={() => handleConfirm()}
                  >
                    <BsTrash size={30} title="Eliminar" />
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    )
  )
}


DeleteModal.propTypes = {
  params: PropTypes.any
}
